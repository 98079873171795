import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import "./LeftMenuControl.scss";
import { useNavigate } from 'react-router-dom';
import GradientIcon, { GradientIconTypes } from '../Icon/GradientIcon';
import Icon from '../Icon';
import CustomIcon from '../Icon/CustomIcon';
import { CUSTOM_ICONS } from '../Icon/CustomIcon/customIcons';
import { useAppSelector } from '../../store';
import { settingsActions } from '../../actions/';

const LeftMenuControl = ({startNewSession}) => {
    const isDashboardPage = window.location.pathname === '/dashboard';
    const [isDashboardActive, setIsDashboardActive] = useState(isDashboardPage);
    const isLeftMenuActive = useAppSelector(state => state.setting.isLeftMenuOpen);

    const dispatch = useDispatch();

    const navigate = useNavigate();
    
    const toggleMenu = () => {
        settingsActions.setIsLeftMenuOpen(!isLeftMenuActive)(dispatch);
    };

    const onDashboardClick = () => {
        if (isDashboardActive) {
            return;
        }
        navigate('/dashboard');
        setIsDashboardActive(true);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey) {
                if (event.key === "n" || event.key === "N") {
                    event.preventDefault();
                    startNewSession();
                    setIsDashboardActive(true);
                }

                if (event.key === "d" || event.key === "D") {
                    event.preventDefault();
                    navigate('/dashboard');
                    setIsDashboardActive(true);
                }

                if (event.key === "s" || event.key === "S") {
                    event.preventDefault();
                    toggleMenu();
                }
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    })

    useEffect(() => {
        if (isDashboardPage !== isDashboardActive) {
            setIsDashboardActive(isDashboardPage);
        }
    }, [isDashboardPage, isDashboardActive]);

    return (
        <div className="menu-control">
            <div className="menu-control-buttons">
                <GradientIcon
                    type={GradientIconTypes.COLORED}
                    icon={<Icon type="add" className="menu-icon add-icon" />}
                    isActive
                    tooltipText="Start new session"
                    tooltipKey="N"
                    onClick={startNewSession}
                />
                <GradientIcon
                    type={GradientIconTypes.GRAY}
                    icon={<CustomIcon className="menu-icon dashboard-icon" d={CUSTOM_ICONS.dashboard.d} />}
                    isActive={isDashboardActive}
                    onClick={onDashboardClick}
                    tooltipText="Dashboard"
                    tooltipKey="D"
                />
                <GradientIcon
                    type={GradientIconTypes.GRAY}
                    icon={<CustomIcon className="menu-icon view-list-icon" d={CUSTOM_ICONS.view_list.d} />}
                    isActive={isLeftMenuActive}
                    onClick={toggleMenu}
                    tooltipText="Sessions"
                    tooltipKey="S"
                />
            </div>
        </div>
    );
};

export default LeftMenuControl;
