import { Message } from "../types";

type WebSocketMessage = 
    | { type: "content"; content: string; isUser: boolean; finished?: boolean; speechMode?: boolean; timestamp: string }
    | { type: "end"; content: string; isUser: boolean; finished: true; speechMode?: boolean; timestamp: string };

export function transformMessages(messagesFromBackend: Message[]): WebSocketMessage[] {
    const transformedMessages: WebSocketMessage[] = [];

    for (const message of messagesFromBackend) {
        transformedMessages.push({
            type: "content",
            content: message.content,
            isUser: true,
            finished: true,
            timestamp: message.timestamp,
        });

  
        transformedMessages.push({
            type: "content",
            content: message.reply,
            isUser: false,
            finished: true,
            timestamp: message.timestamp,
        });
    }

    return transformedMessages;
};
