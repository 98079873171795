export function getDaycycle() {
    const hours = new Date().getHours();

    if (hours >= 6 && hours < 12) {
        return 'morning';
    } else if (hours >= 12 && hours < 18) {
        return 'afternoon';
    } else if (hours >= 18 && hours <= 23) {
        return 'evening';
    } else {
        return 'night';
    }
}

