import React from 'react';
import './Header.scss';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';


const Header = () => {
    return (
        <div className="header p-4 d-flex align-items-center position-absolute z-3">
            <Logo/>
        </div>
    );
};

export default Header;
