import { useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useGSAP } from "@gsap/react";

const useCompanionAnimation = () => {
    const companionFirstRef = useRef<HTMLDivElement | null>(null);
    const companionSecondRef = useRef<HTMLDivElement | null>(null);
    const companionThirdRef = useRef<HTMLDivElement | null>(null);
    const companionSpanRef = useRef<HTMLSpanElement | null>(null);
    const companionTabletRef = useRef<HTMLImageElement | null>(null);

    useGSAP(() => {
        const tl = gsap.timeline();
        const tl2 = gsap.timeline({ paused: true });

        tl.fromTo(
            companionFirstRef.current,
            { y: 300, opacity: 0 },
            { y: 0, opacity: 1, duration: 6 },
            0
        )
            .fromTo(
                companionSecondRef.current,
                { y: 300, opacity: 0 },
                { y: 0, opacity: 1, duration: 6 },
                0
            )
            .fromTo(
                companionThirdRef.current,
                { y: 300, opacity: 0 },
                { y: 0, opacity: 1, duration: 6 },
                0
            )
            .fromTo(
                companionSpanRef.current,
                { backgroundPosition: "0%" },
                { backgroundPosition: "-100%", duration: 4 }
            )
            .fromTo(
                companionTabletRef.current,
                { y: 100, opacity: 0 },
                { y: 80, opacity: 1, duration: 4 },
                0
            );

        tl2.to(companionTabletRef.current, {
            width: '30%'
        })
        ScrollTrigger.create({
            animation: tl,
            trigger: ".companion-container",
            toggleActions: "play reverse resume none",
            start: "top top",
            end: "bottom top",
            pin: true,
            scrub: true,
            onLeave: () => {
                tl2.play()
            },
            onEnterBack: () => {
                tl2.reverse()
            }
        });
    }, []);

    return {
        companionFirstRef,
        companionSecondRef,
        companionThirdRef,
        companionSpanRef,
        companionTabletRef,
    };
};

export default useCompanionAnimation;
