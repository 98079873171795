import { authConstants } from '../constants';

interface AuthState {
    user: { name: string; email: string } | null;
    accessToken: string | null;
  }
  
const initialState: AuthState = {
    user: null,
    accessToken: null,
};

export function authReducer(state = initialState, action: any) {
    switch (action.type) {
        case authConstants.SET_TOKEN:
            return {
                ...state,
                accessToken: action.data.accessToken,
            };
        case authConstants.SET_USER:
            return {
                ...state,
                user: action.data.user,
            };
        case authConstants.LOGOUT:
            return {
                ...state,
                user: null,
                accessToken: null,
            };
        case authConstants.UPDATE_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.data.accessToken,
            };
        default:
            return state;
    }
}
