import { authConstants } from '../constants';
import { StoreDispatchType } from '../store';

function setToken(accessToken: string) {
    return (dispatch: StoreDispatchType) => {
        dispatch((data => {
            return { type: authConstants.SET_TOKEN, data }
        })({accessToken}));
    };
}

function setUser(user: any) {
    return (dispatch: StoreDispatchType) => {
        dispatch((data => {
            return { type: authConstants.SET_USER, data }
        })({user}));
    };
}

function logout() {
    return (dispatch: StoreDispatchType) => {
        dispatch((data => {
            return { type: authConstants.LOGOUT, data }
        })({}));
    };
}

function updateAccessToken(accessToken: string) {
    return (dispatch: StoreDispatchType) => {
        dispatch((data => {
            return { type: authConstants.UPDATE_ACCESS_TOKEN, data }
        })({accessToken}));
    };
}

export const authActions = {
    setToken,
    setUser,
    logout,
    updateAccessToken
};
