import { ScrollTrigger } from "gsap/all";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const useLeadershipAnimation = (
    firstSubtitleRef: React.RefObject<HTMLDivElement>,
    secondSubtitleRef: React.RefObject<HTMLDivElement>,
    spanRef: React.RefObject<HTMLSpanElement>
) => {
    useGSAP(() => {
        gsap.registerPlugin(ScrollTrigger);

        const tl = gsap.timeline();

        tl.fromTo(
            firstSubtitleRef.current,
            { y: 100, opacity: 0 },
            { y: 0, opacity: 1, duration: 1 }
        )
            .fromTo(
                secondSubtitleRef.current,
                { y: 110, opacity: 0 },
                { y: 0, opacity: 1, duration: 1 }
            )
            .fromTo(
                spanRef.current,
                { backgroundPosition: "0%" },
                { backgroundPosition: "-100%", animationDirection: 5 }
            );

        ScrollTrigger.create({
            animation: tl,
            trigger: ".leadership-container",
            toggleActions: "play reset reverse none",
            start: "top top",
            end: "bottom top",
            pin: true,
            scrub: true,
        });
    }, []);
};

export default useLeadershipAnimation;
