import React, { useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import Lottie from "lottie-react";
import './TextInput.scss';
import Icon from '../Icon';
import GradientIcon, { GradientIconTypes } from '../Icon/GradientIcon';
import Text from '../Text';
import MilestoneSetter from './MilestoneSetter';
import { MilestoneId } from '../../types';


type Props = {
    onSubmit?: (e: any, show: boolean) => void,
    className?: string
    isProcessing?: boolean
    isSetMilestone?: boolean
    setIsSetMilestone?: (value: boolean) => void
    milestoneType?: MilestoneId
    milestoneText?: string
    relativeClassname?:string;
    updateMilestone?: (milestone: any) => void
};

function TextInput({
    onSubmit,
    className,
    isProcessing,
    isSetMilestone,
    setIsSetMilestone,
    milestoneType,
    milestoneText,
    relativeClassname,
    updateMilestone
}: Props) {
    const [inputValue, setInputValue] = useState('');
    const [isTyped, setIsTyped] = useState(false);
    const textareaRef = useRef(null);
    const intl = useIntl();

    const adjustHeight = () => {
        const textarea = textareaRef.current;
    
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    };
    
    const handleInput = (e) => {
        adjustHeight();
        setInputValue(e.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            if(inputValue.trim() !== '') {
                event.preventDefault();
                onSubmit(inputValue, false);
                setInputValue('');
            }
        }
    };

    const onButtonClick = () => {
        if (!isProcessing && isTyped) {
            if(inputValue.trim() !== '') {
                onSubmit(inputValue, false);
                setInputValue('');
            }
        }
    }

    const getIcon = () => {
        if (isProcessing) return <Icon type="cross" className="menu-icon" />; 
        else if (isTyped) return <Icon type="arrow_forward" className="menu-icon" />; 
        else return <Icon type="microphone" className="menu-icon" />;
    };

    useEffect(() => {
        if (inputValue.trim() !== '') {
            setIsTyped(true);
        } else {
            setIsTyped(false);
        }
    }, [inputValue]);

    return (
        <div className={`position-relative text-input-outer-wrapper ${relativeClassname} ${isSetMilestone ? 'milestone' : ''}`}>
            {isSetMilestone ?
                <MilestoneSetter
                    milestoneType={milestoneType}
                    milestoneText={milestoneText}
                    setIsSetMilestone={setIsSetMilestone}
                    updateMilestone={updateMilestone}
                />
            :
                <div className={`text-input-wrapper ${className ? className : ''} ${isProcessing ? 'processing' : ''}`}>
                    <GradientIcon
                        type={GradientIconTypes.COLORED}
                        icon={getIcon()}
                        isActive
                        className="text-input__microphone position-absolute"
                        shadow={true}
                        onClick={onButtonClick}
                    />
                    <textarea
                        className="text-input w-100"
                        placeholder={isProcessing ? '' : intl.formatMessage({
                            id: 'eth.text_input.placeholder',
                            defaultMessage: 'What\'s on your mind?'
                        })}
                        value={inputValue}
                        onChange={(e) => handleInput(e)}
                        onKeyDown={handleKeyDown}
                        disabled={isProcessing}
                        rows={1}
                        ref={textareaRef}
                    />
                    {isProcessing &&
                        <div className="text-input-processing-placeholder">
                            <Lottie className="placeholder-icon" animationData={require('../../assets/lottie/eth-bird.json')} loop={true} />
                            <Text className="placeholder-text" label="eth.text_input.processing" message="Processing"/>
                            <div className="wave"></div>
                        </div>
                    }
                    
                </div>
            }
        </div>
    );
}

export default TextInput;
