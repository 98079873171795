import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sessionActions } from "../../actions";


interface ControlRowProps {
    resetChat: () => void;
    speechMode: () => void;
    uploadFile: () => void;
    changeLanguage?: any;
}

function ControlRow({ resetChat, speechMode, uploadFile, changeLanguage }: ControlRowProps) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const reset = () => {
        localStorage.setItem("activeSessionId", "");
        sessionActions.setActiveSession(null)(dispatch);
        resetChat();
        navigate("/dashboard");
    };

    return (
        <div className="control-row">
            <button className="reset-button" onClick={reset}>
                Reset Chat
            </button>
            <button className="reset-button" onClick={speechMode}>
                Speech Mode
            </button>
            <button className="reset-button" onClick={uploadFile}>
                Upload File
            </button>

        </div>
    );
}

export default ControlRow;
