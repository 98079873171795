import React, { ForwardedRef } from 'react';
import './Ready.scss';
import { FaPlus } from 'react-icons/fa';
import TextInput from '../../../../components/TextInput';
import { ReadyBirdNoise } from '../../../../assets/img';
import { history, useAppSelector } from '../../../../store';
import { sessionActions } from '../../../../actions';
import { useDispatch } from 'react-redux';

const Ready = ({ spanRef }: { spanRef: ForwardedRef<HTMLDivElement> }) => {
    const dispatch = useDispatch();
    const userState = useAppSelector(state => state.auth.user);
    const submitMessage = message => {
        if (userState) {
            sessionActions.sendMessage(message)(dispatch);
            history.push('/session');
        } else {
            history.push('/login');
        }
    };
    return (
        <>
            <ReadyBirdNoise className="ready-bird" />
            <div className="ready-content">
                <div className="ready-title">
                    <span className="ready-span" ref={spanRef}>
                        Ready
                    </span>{' '}
                    to give it a go?
                </div>
                <TextInput className="ready-input" relativeClassname="ready-input" onSubmit={submitMessage} />
                <div className="ready-desc">
                    Your journey to confident, impactful leadership starts here. With the Leadership Companion, you'll have
                    24/7 access to personalized guidance, ETH-specific insights, and powerful tools to navigate any
                    leadership challenge.
                </div>
                <button className="ready-cta" onClick={submitMessage}>
                    <FaPlus size={12} /> <div>Start new session now</div>
                </button>
            </div>
        </>
    );
};

export default Ready;
