import React from 'react';
import { CustomIconProps } from './customIcons';
import './CustomIcon.scss';

const CustomIcon: React.FC<CustomIconProps> = ({
  d,
  fill = "white",
  opacity = 1,
  width = 24,
  height = 24,
  className
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={opacity}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d={d}
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default CustomIcon;
