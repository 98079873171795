import React from 'react';
import Welcome from './Sections/Welcome';
import Footer from './Sections/Footer';
import Companion from './Sections/Companion';
import Approach from './Sections/Approach';
import Expect from './Sections/Expect';
import FaqsSection from './Sections/Faqs';
import LeaderShipSection from './Sections/Leadership';
import './LandingContainer.scss';
import Innovation from './Sections/Innovation';
import Seconds from './Sections/Seconds';

function LandingPage() {
    return (
        <>
            <Welcome />
            <section className="features">
                <LeaderShipSection />
                <Companion />
                <Approach />
                <Expect />
                <FaqsSection />
            </section>
            <section className="innovation">
                <Innovation />
                <Seconds />
            </section>
            <Footer />
        </>
    );
}

export default LandingPage;
