import React from "react";
import './RightSidebar.scss';


interface RightSidebarProps {
    sources: string[];
}

const RightSidebar = ({ sources }: RightSidebarProps) => {
    const [isOpen] = React.useState(true);

    const basePath = "/pdfs";

    const fileData = sources.map((
        source: string | number | boolean,
        index: any) => ({
        id: index,
        name: source,
        url: `${basePath}/${encodeURIComponent(source)}`,
    }));

    return sources.length > 0 && (
        <div className={`right-sidebar ${isOpen ? "open" : ""}`}>
            {fileData.map((file: any) => (
                <div key={file.id} className="file">
                    <a href={file.url} target="_blank" rel="noreferrer">
                        {file.name}
                    </a>
                </div>
            ))}
        </div>
    );
}

export default RightSidebar;
