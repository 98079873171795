import React from "react";
import TabletImage from "../../../../assets/img/tablet.webp";
import { CompanionBird, TabletBirdNoise } from "../../../../assets/img";
import useCompanionAnimation from "../../../../hooks/useCompanion";

import "./Companion.scss";

const MemoizedCompanionBird = React.memo(CompanionBird);

const Companion = () => {
  const {
    companionFirstRef,
    companionSecondRef,
    companionThirdRef,
    companionSpanRef,
    companionTabletRef,
  } = useCompanionAnimation();

  return (
    <div className="companion-container">
      <h2 className="companion-title">
        <div className="companion-subtitle-first" ref={companionFirstRef}>
          Meet the{" "}
          <span className="tablet-bird">
            <MemoizedCompanionBird className="companion-bird" />
            <TabletBirdNoise className="companion-bird-noise" />
          </span>
          <span className="companion-span" ref={companionSpanRef}>
            Leadership Companion:
          </span>
          <br />
        </div>
        <div className="companion-subtitle-second" ref={companionSecondRef}>
          your AI-powered companion for
        </div>
        <div className="companion-subtitle-second" ref={companionThirdRef}>
          confident, effective leadership.
        </div>
      </h2>
      <img
        src={TabletImage}
        ref={companionTabletRef}
        alt="tablet"
        className="companion-tablet"
      />
    </div>
  );
};

export default Companion;
