import React from 'react';
import { NavigationIndicator } from '@reverse-ui/react';
import "./ProgressBar.scss";
import { Session } from '../../types';
import { MILESTONES } from '../../constants/session.constants';
import Tooltip from '../Tooltip';
import Icon from '../Icon';

interface ProgressBarProps {
    activeSession?: Session;
}


const ProgressBar = ({activeSession}: ProgressBarProps ) => {
    const emptyArray = Array(20).fill('');

    return (
        <div className="progress-bar-wrapper position-absolute z-2">
            {MILESTONES.map((milestone, milestoneIndex) => {
                const isActive = Boolean(activeSession && activeSession[milestone.id]);

                return (
                    <div key={milestone.id} className={`progress-bar-milestone ${isActive || milestone.id === 'discovery' ? 'active' : ''}`}>
                        {milestoneIndex >= 1 && milestoneIndex < MILESTONES.length && (
                            <NavigationIndicator
                                items={emptyArray}
                            />
                        )}
                        <div className="progress-bar-milestone-point-wrapper">
                            <div className="progress-bar-milestone-point"></div>
                            <div className="progress-bar-milestone-tooltip">
                                <Tooltip
                                    text={milestone.timelineState}
                                    iconLeft={isActive || milestone.id === 'discovery' ? milestone.icon : <Icon type="lock" className="milestone-icon"/>}
                                    inactive={!(isActive || milestone.id === 'discovery')}
                                />
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ProgressBar;
