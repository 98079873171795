/* eslint-disable react/style-prop-object */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AuthenticationResult } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import "./Login.scss";
import { history } from '../../store';
import { loginRequest } from '../../config/msalConfig';
import { authActions } from '../../actions';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Text from '../../components/Text';


const Login = () => {
    const [error, setError] = React.useState<string | null>(null);
    const [isSignedIn, setIsSignedIn] = React.useState(false);
    const { accounts, instance } = useMsal();
    const dispatch = useDispatch();
    const activeSessionId = localStorage.getItem("activeSessionId");

    const handleLogin = async () => {
        try {
            setError(null);
            const response: AuthenticationResult = await instance.loginPopup(loginRequest);

            if (response) {
                const { accessToken, account } = response;
                if (accessToken && account) {
                    localStorage.setItem('authToken', accessToken);
                    authActions.setToken(accessToken)(dispatch);
                    const user = {
                        name: response.account.name || "",
                        email: response.account.username || "",
                    };
                    authActions.setUser(user)(dispatch);
                    setIsSignedIn(true);
                } else {
                    throw new Error('Login failed');
                }
            }
        } catch (error) {
            setError('Login failed');
            console.error("Login failed:", error);
        }
    }

    useEffect(() => {
        if (isSignedIn && accounts && accounts.length > 0) {
            if (activeSessionId) {
                history.push(`/session/${activeSessionId}`);
            } else {
                history.push('/dashboard');
            }
        }
    }, [isSignedIn, accounts, activeSessionId]);

    return (
        <div className="login-page">
            <div className="login-container">
                <div className='login-title-wrapper'>
                    <Icon type="origami" className="position-relative"/>
                    <Text className="login-title" label="eth.login.title" message="Welcome to Leadership Companion. Let’s make an impact today."/>
                </div>
                <Button
                    style="secondary"
                    text="Sign in with Microsoft"
                    onClick={handleLogin}
                    icon={<Icon type="sso" className="sso-icon"/>}
                />
                {error && <div className="login-error">{error}</div>}
            </div>
        </div>
    );
}


export default Login;
