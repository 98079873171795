import React from "react";
import { Milestone, TimelineState } from "../types";
import Icon from "../components/Icon";

export const sessionConstants = {
    SET_MESSAGE: 'SET_MESSAGE',
    SET_SESSIONS: 'SET_SESSIONS',
    SET_ACTIVE_SESSION: 'SET_ACTIVE_SESSION',
    UPDATE_PREV_ACTIVE_SESSION: 'UPDATE_PREV_ACTIVE_SESSION',
    ADD_SESSION: 'ADD_SESSION',
    SET_SOURCES: 'SET_SOURCES',
    SET_MILESTONE: 'SET_MILESTONE'
};

export const MILESTONES: Milestone[] = [
    {
        id: 'discovery',
        timelineState: TimelineState.DISCOVERY,
        order: 0,
        icon: <Icon type="discovery" className="milestone-icon"/>
    },
    {
      id: 'problem',
      label: 'Situation',
      backendVariable: 'problem',
      timelineState: TimelineState.SESSION_GOAL_SETTING,
      order: 1,
      icon: <Icon type="star_border" className="milestone-icon"/>
    },
    {
      id: 'goal',
      label: 'Session Goal',
      backendVariable: 'goal',
      timelineState: TimelineState.EXPLORING_SOLUTIONS,
      order: 2,
      icon: <Icon type="solution" className="milestone-icon"/>
    },
    {
      id: 'solution',
      label: 'Key Enabler',
      backendVariable: 'solution',
      timelineState: TimelineState.ACTION_PLANNING,
      order: 3,
      icon: <Icon type="solution" className="milestone-icon"/>
    },
    {
      id: 'action',
      label: 'Commitments',
      backendVariable: 'action',
      timelineState: TimelineState.COMMITMENTS,
      order: 4,
      icon: <Icon type="commitments" className="milestone-icon"/>
    },
  ];
