import React from 'react';
import "./Tooltip.scss";

interface TooltipProps {
    text: string;
    tooltipKey?: string;
    iconLeft?: React.ReactNode;
    inactive?: boolean;
}

const Tooltip = ({ text, tooltipKey, iconLeft, inactive }: TooltipProps ) => {

    return (
        <div className={`tooltip-wrapper ${inactive ? 'inactive' : ''}`}>
            {iconLeft && (
                <div className="tooltip-icon">
                    {iconLeft}
                </div>
            )}
            <div className="tooltip-text">{text}</div>
            {tooltipKey && (
                <div className="tooltip-key">
                    <div className="tooltip-key-mask">
                        <div className="tooltip-key-text">
                            {tooltipKey}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Tooltip;
