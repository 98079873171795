import React from "react";
import "./Footer.scss";
import Icon from "../../../../components/Icon";
import { MainLogo } from "../../../../assets/img";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footerContainer">
        <div className="footerMain">
          <div className="footerSection">
            <MainLogo className="footer-logo" />
            <p className="feedbackQuestion">Got any questions or feedback?</p>
            <a href="/contact" className="footerEmail">
              Send email
            </a>
          </div>

          <div className="footerMidSection">
            <h2 className="footerSectionTitle">Explore further</h2>
            <ul className="footerLinks">
              <li>
                <a href="/home">ETH Globe 2023 - Pages 24-28</a>
              </li>
              <li>
                <a href="/home">Leadership, Psychological Well-Being, and O</a>
                ...
              </li>
              <li>
                <a href="/home">Unearthing AI coaching chatbots capabilities</a>
                ...
              </li>
              <li>
                <a href="/home">Lifelong learning: Science professors need l</a>
              </li>
              <li>
                <a href="/home">Text-based Healthcare Chatbots Supporting</a>
                ...
              </li>
              <li>
                <a href="/home">You can ask a chatbot things you might not d</a>
                ...
              </li>
              <li>
                <a href="/home">Comparing artificial intelligence and human</a>
                ...
              </li>
              <li>
                <a href="/home">Forschung & Lehre</a>
              </li>
            </ul>
          </div>

          <div className="footerEndSection ">
            <h2 className="footerSectionTitle">Follow us</h2>
            <div className="footerSocial">
              <a href="/home" aria-label="Follow us on X (Twitter)">
                <Icon type={"twitter"} />
              </a>
              <a href="/home" aria-label="Follow us on LinkedIn">
                <Icon type={"linkedIn"} />
              </a>
            </div>
          </div>
        </div>
        <div className="footer-seperator-black" />
        <div className="footer-seperator" />
        <div className="footerBottom">
          <div>© 2024 Swiss Federal University of Technology Zurich</div>
          <div className="footerLegal">
            <a href="/site-notice">Site notice</a>
            <a href="/accessibility">Accessibility statement</a>
            <a href="/disclaimer">Disclaimer & copyright</a>
            <a href="/privacy">Data privacy</a>
          </div>
        </div>
      </div>
    </footer>
  );
}
