import { PublicClientApplication, Configuration } from '@azure/msal-browser';

export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: process.env.REACT_APP_AUTHORITY,
        redirectUri: process.env.REACT_APP_REDIRECT_URI,
    },
};

export const loginRequest = {
    scopes: [`${msalConfig.auth.clientId}/.default`],
};

export const msalInstance = new PublicClientApplication(msalConfig);
