import React from 'react';
import './Message.scss';
import Text from '../../../components/Text';
import Icon from '../../../components/Icon';
import { Message as MessageType } from '../../../types';
import { useAppSelector } from '../../../store';
import ReactMarkdown from 'react-markdown';

type Props = {
    message: MessageType,
};


const Message = ({message}: Props) => {
    const userState = useAppSelector(state => state.auth.user);

    return (
        <div className="position-relative">
            {message.content && message?.isUser &&
                <div className="message message__user d-flex w-auto">
                    <div className="message__user-icon">
                        <span>{userState.name[0]}</span>
                    </div>
                    <Text className="w-100"><ReactMarkdown>{message.content}</ReactMarkdown></Text>
                </div>
            }
            {message.content && !message?.isUser &&
                <div className="message message__reply d-flex w-auto">
                    <Icon type="origami_small"/>
                    <Text className="w-100"><ReactMarkdown>{message.content}</ReactMarkdown></Text>
                </div>
            }
        </div>
    );
};

export default Message;
