export interface Message {
  id: number;
  content: string;
  reply: string;
  timestamp: string;
  isUser?: boolean;
}

export interface Session {
  id: number;
  hash_id: string;
  goal: string | null;
  problem: string | null;
  solution: string | null;
  action: string | null;
  created_at: string;
  updated_at: string;
  messages: Message[];
  status: string;
}

export type MilestoneId = 'discovery' | 'problem' | 'goal' | 'solution' | 'action';

export interface Milestone {
  id: MilestoneId;
  label?: string;
  backendVariable?: string;
  timelineState: TimelineState;
  order: number;
  icon: React.ReactNode;
}

export enum TimelineState {
  DISCOVERY = 'Discovery',
  SESSION_GOAL_SETTING = 'Session Goal Setting',
  EXPLORING_SOLUTIONS = 'Exploring Solutions',
  ACTION_PLANNING = 'Action Planning',
  COMMITMENTS = 'Commitments',
}
