import React from "react";

function LeftContainer({ objective, problem, goal, solution, action }) {
    return (
        <div id="left-container">
            <h3>Objective</h3>
            <p id="objective">{objective}</p>
            <h3>Problem</h3>
            <p id="problem">{problem}</p>
            <h3>Goal</h3>
            <p id="goal">{goal}</p>
            <h3>Solution</h3>
            <p id="solution">{solution}</p>
            <h3>Action</h3>
            <p id="action">{action}</p>
            <div id="state_machine"></div>
        </div>
    );
}

export default LeftContainer;
