import { useRef } from "react";
import gsap from "gsap";
import { Observer } from "gsap/Observer";
import { useGSAP } from "@gsap/react";

const useWindowAnimation = () => {
    const welcomeRef = useRef<HTMLDivElement | null>(null);

    useGSAP(() => {
        gsap.registerPlugin(Observer);

        const observer = Observer.create({
            target: '.welcome',
            type: "pointer",
            onRight() {
                gsap.to('.hand', { x: '10%', duration: 1 })
                gsap.to('.bird', { x: '0%', duration: 1 })
            },
            onLeft() {
                gsap.to('.hand', { x: '18%', duration: 1 })
                gsap.to('.bird', { x: '14%', duration: 1 })
            },
            onStop() {
                gsap.to('.hand', { x: '14.5%', duration: 1 })
                gsap.to('.bird', { x: '7%', duration: 1 })
            },
            onMove() { }
        });

        gsap.fromTo(
            ".linecontainer",
            { height: '0%', opacity: 0, transformOrigin: 'top' },
            { height: '100%', opacity: 1, duration: 1.3 }
        );

        gsap.fromTo(
            ".hand",
            { y: '100%', x: '14.5%', opacity: 0 },
            { y: '0%', opacity: 1, duration: 1.3 }
        );

        gsap.fromTo(
            ".handpoints",
            { y: '100%', x: '14.2%', opacity: 0 },
            { y: '-11%', opacity: 1, duration: 1.5 }
        );

        gsap.fromTo(
            ".birdpoints",
            { y: "90%", x: '5%', opacity: 0 },
            { y: '-38%', opacity: 1, duration: 1.5 }
        );

        gsap.fromTo(
            ".birdpointswhite",
            { y: "90%", x: '7%', opacity: 0 },
            { y: '-30%', opacity: 1, duration: 1.5 }
        );

        gsap.fromTo(
            ".bird",
            { y: "90%", x: '7%', opacity: 0 },
            { y: '-30%', opacity: 1, duration: 1.3 }
        );

        gsap.fromTo(
            ".title-your",
            { y: 110, opacity: 0 },
            { y: 0, opacity: 1, duration: 1, delay: 2 }
        );

        gsap.fromTo(
            ".title-leadership",
            { y: 110, opacity: 0 },
            { y: 0, opacity: 1, duration: 1.2, delay: 2.2 }
        );

        gsap.fromTo(
            ".title-personal",
            { y: 100, opacity: 0 },
            { y: 0, opacity: 1, duration: 1, delay: 2.2 }
        );

        gsap.fromTo(
            ".title-companion",
            { y: 100, opacity: 0 },
            { y: 0, opacity: 1, duration: 1.2, delay: 2.4 }
        );

        gsap.fromTo(
            ".title-subtitle",
            { y: 100, opacity: 0 },
            { y: 0, opacity: 1, duration: 1.2, delay: 2.6 }
        );

        gsap.fromTo(
            ".title-cta",
            { y: 100, opacity: 0 },
            { y: 0, opacity: 1, duration: 1.2, delay: 2.7 }
        );

        gsap.fromTo(
            ".title-subheading",
            { y: -130, opacity: 0 },
            { y: 0, opacity: 1, duration: 1, delay: 2 }
        );

        gsap.fromTo(
            ".title-logo",
            { y: -100, opacity: 0 },
            { y: 0, opacity: 1, duration: 1, delay: 2 }
        );

        return () => {
            observer.kill();
        };
    }, []);

    return { welcomeRef };
};

export default useWindowAnimation;
