import React from "react";
import { ExpectBird, ExpectNoise } from "../../../../assets/img";
import { TextBox } from "./TextBox";
import { textContent } from "../../../../constants";
import useExpectAnimation from "../../../../hooks/useExpect";

import "./Expect.scss";

const MemoizedExpectBird = React.memo(ExpectBird);

const Expect: React.FC = () => {
  const { handleCard, textBoxRefs, highlightRef } = useExpectAnimation();

  return (
    <div className="expect-container">
      <div className="expect-left-container">
        <MemoizedExpectBird className="expect-bird" />
        <ExpectNoise className="expect-noise" />
      </div>
      <div className="expect-right-container">
        <h2 className="expect-heading">
          What you can{" "}
          <span className="expect-highlight" ref={highlightRef}>
            expect
          </span>
        </h2>
        {textContent.map((item, index) => (
          <TextBox
            key={index}
            title={item.title}
            text={item.text}
            ref={(el) => (textBoxRefs.current[index] = el)}
            onClick={() => {
              handleCard(index);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Expect;
