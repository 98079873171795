import React, { useEffect, useRef, useState } from 'react';
import './SessionHeader.scss';
import Text from '../../../components/Text';
import Icon from '../../../components/Icon';
import { deleteSession } from '../../../utilities/apiService';
import HeaderIllustration from '../../../components/HeaderIllustration';
import { getDaycycle } from '../../../common';
import { history, useAppSelector } from '../../../store';
import { sessionActions } from '../../../actions';
import { useDispatch } from 'react-redux';

type Props = {
    title: string,
    sessionHash: string
};


const SessionHeader = ({title, sessionHash}: Props) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);
    const refIcon = useRef<HTMLDivElement | null>(null);
    const dispatch = useDispatch();
    const sessions = useAppSelector(state => state.session.sessions);

    const handleMenuClick = () => setIsMenuOpen(!isMenuOpen);

    const handleClickOutside = (event: MouseEvent) => {
        if (
            ref.current && !ref.current.contains(event.target as Node) &&
            refIcon.current && !refIcon.current.contains(event.target as Node)
        ) {
            setIsMenuOpen(false);
        }
    };

    const handleDeleteSession = (hash: string) => {
        try {
            deleteSession(hash)
            .then(() => {
                localStorage.setItem('activeSessionId', '');
                sessionActions.setActiveSession(null)(dispatch);
                sessionActions.loadSessions(sessions.filter((s) => s.hash_id !== hash))(dispatch);
                history.push('/session');
                setIsMenuOpen(false);
            })
            .catch(() => {
                console.log('Error occurred when deleting sessions');
            });
        } catch (error) {
            console.error('Error occurred when deleting sessions', error);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    return (
        <div className="session-header position-absolute w-100 z-2">
            <HeaderIllustration small={true}/>
            <div className="session-header__container position-relative container d-flex">
                {title &&
                    <Text left className="session-header__title">{title}</Text>
                }
                {!title &&
                    <Text left className="session-header__title" label="eth.sessions_header.empty_session.title" message="New {dayCycle} session" values={{dayCycle: getDaycycle()}}/>
                }
                <div ref={refIcon}>
                    <Icon type="more" className="session-header__icon cursor-pointer" onClick={handleMenuClick}/>
                </div>
                {isMenuOpen && (
                    <div ref={ref} className="session-header__menu position-absolute">
                        <div className="d-flex cursor-pointer p-3 align-items-center gap-2">
                            <Icon type="flag"/>
                            <Text white bold label="eth.session_header.menu.report_problem" message="Report a problem"/>
                        </div>
                        <div
                            className="d-flex cursor-pointer p-3 align-items-center gap-2"
                            onClick={() => handleDeleteSession(sessionHash)}
                        >
                            <Icon type="delete"/>
                            <Text bold error label="eth.session_header.menu.delete_session" message="Delete session"/>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};


export default SessionHeader;
