import React, { useEffect, useState } from 'react';
import './Faqs.scss';
import {
    BlueTwist,
    GradientIcon,
    HeartIcon,
    LockIcon,
    MissionIcon,
    ScienceIcon,
    SecurityIcon,
} from '../../../../assets/img';
import { useGSAP } from '@gsap/react';
import gsap, { ScrollTrigger } from 'gsap/all';
import { qnaData, QnaList } from './QnaBox';
import { getFaqs } from '../../../../utilities/apiService';

const topGuidanceData = [
    {
        icon: <LockIcon />,
        title: '100% data anonymity',
        description:
            'Your leadership journey is personal and fully <br /> anonymized. Your data is non-traceable to your identity.',
        className: 'two-cards',
        mainClass: 'col-span-6 card-1',
    },
    {
        icon: <HeartIcon />,
        title: 'Continuous support',
        description:
            'Get the personalized guidance you need, to grow and <br /> develop on your own terms, anytime and anywhere.',
        className: 'two-cards heart-card',
        mainClass: 'col-span-6 card-2',
    },
];

const guidanceData = [
    {
        icon: <SecurityIcon />,
        title: 'Structured but flexible',
        description: 'A solid framework that adapts to different <br /> leadership needs and challenges.',
        className: 'one-big-card',
        mainClass: 'bottom-big-cards card-3',
    },
    {
        icon: <ScienceIcon />,
        title: 'Scientifically founded',
        description:
            'Rooted in science, powered by proven psychological <br /> principles and cutting-edge leadership insights.',
        className: 'two-small-cards',
        mainClass: 'bottom-small-cards card-4',
    },
    {
        icon: <MissionIcon />,
        title: 'Mission driven',
        description: 'Designed with a human-centric approach, <br /> emphasizing growth, empowerment, and core values.',
        className: 'two-small-cards',
        mainClass: 'bottom-small-cards card-5',
    },
];
const FaqsSection = () => {
    const [qnaList, setQnaList] = useState([]);

    useGSAP(() => {
        gsap.registerPlugin(ScrollTrigger);

        const tl = gsap.timeline();

        tl.fromTo('.faq-background', { opacity: 0 }, { opacity: 1, duration: 3 })
            .fromTo('.faq-twist', { opacity: 0 }, { opacity: 1, duration: 3 }, 0)
            .fromTo('.guidance-title', { opacity: 0 }, { opacity: 1, duration: 3 })
            .fromTo('.guidance-span', { backgroundPosition: '0%' }, { backgroundPosition: '-100%' })
            .fromTo('.guidance-description', { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 1 })
            .fromTo('.card-1', { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 2 })
            .fromTo('.card-2', { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 2.2 })
            .fromTo('.card-3', { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 2.4 })
            .fromTo('.card-4', { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 2.6 })
            .fromTo('.card-5', { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 2.8 });

        const tl2 = gsap.timeline();
        tl2.fromTo(
            '.qna-span',
            { backgroundPosition: '0%' },
            {
                backgroundPosition: '-100%',
            }
        );
        ScrollTrigger.create({
            animation: tl,
            trigger: '.guidance-container',
            toggleActions: 'play reset reverse none',
            start: 'top top',
            end: 'bottom top',
            pin: '.faq-container',
            scrub: true,
        });

        ScrollTrigger.create({
            animation: tl2,
            trigger: '.qna-container',
            toggleActions: 'play reset reverse none',
            start: 'bottom top',
            end: '+=300 top',
            pin: '.faq-container',
            scrub: true,
        });
    }, []);

    useEffect(() => {
        const fetchFaq = async () => {
            try {
                const response = await getFaqs();
                if (response) {
                    setQnaList(response);
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchFaq();
    }, []);

    return (
        <section className="faq-container">
            <img src="./faq.webp" className="faq-background" alt="background" />
            <BlueTwist className="faq-twist" />
            <div className="guidance-container">
                <div className="guidance-top">
                    <div className="guidance-title">
                        <div className="guidance-subtitle-first">
                            Tailored <span className="guidance-span">guidance</span> at
                        </div>
                        <br className="guidance-br" />
                        <div className="guidance-subtitle-second">your fingertips</div>
                    </div>
                    <div className="guidance-description">
                        <div className="guidance-details">
                            Leadership challenges are unique, and so are you. The Leadership Companion delivers personalized
                            advice that evolves with your journey at ETH Zürich. Whether you're navigating team dynamics,
                            making critical decisions, or planning your leadership development, our AI-powered app provides
                            instant, context-aware support.
                        </div>
                    </div>
                </div>
                <div className="guidance-top-cards">
                    {topGuidanceData.map(card => (
                        <GuidanceContainer
                            icon={card.icon}
                            title={card.title}
                            description={card.description}
                            className={card.className}
                            mainClass={card.mainClass}
                        />
                    ))}
                </div>
                <div className="guidance-bottom-cards">
                    {guidanceData.map(card => (
                        <GuidanceContainer
                            icon={card.icon}
                            title={card.title}
                            description={card.description}
                            className={card.className}
                            mainClass={card.mainClass}
                        />
                    ))}
                </div>
            </div>

            <div className="qna-container">
                <div className="qna-heading">
                    Got <span className="qna-span">questions?</span>
                </div>
                <div className="qna-shadow">
                    <div className="black-gradient">
                        <GradientIcon />
                    </div>
                    <div className="qna-questions">
                        <div className="qna-question-box">
                            <QnaList qnaData={qnaList} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FaqsSection;

const GuidanceContainer = ({
    icon,
    title,
    description,
    className = 'two-cards',
    mainClass,
}: {
    icon?: any;
    title?: string;
    description?: string;
    className?: string;
    mainClass?: string;
}) => {
    return (
        <div className={mainClass}>
            <div className={className}>
                <div className="card-icon">{icon}</div>
                <div className="card-title">{title}</div>
                <div className="card-description" dangerouslySetInnerHTML={{ __html: description }}></div>
            </div>
        </div>
    );
};
