import React, { useRef } from 'react';
import './Innovation.scss';
import { useGSAP } from '@gsap/react';
import { InnovationBird } from '../../../../assets/img';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

const Innovation = () => {
    const spanRef = useRef(null);
    const desRef = useRef(null);

    useGSAP(() => {
        const tl = gsap.timeline();
        tl.fromTo(
            '.innovation',
            {
                backgroundSize: '120%',
                backgroundPosition: 'center center',
            },
            {
                backgroundSize: '110%',
                backgroundPosition: 'center center',
            }
        )
            .fromTo('.innovation-title', { y: 100, opacity: 0 }, { y: 0, opacity: 1 })
            .fromTo(spanRef.current, { backgroundPosition: '0%' }, { backgroundPosition: '-100%' })
            .fromTo(desRef.current, { y: 100, opacity: 0 }, { y: 0, opacity: 1 });

        ScrollTrigger.create({
            animation: tl,
            trigger: '.innovation-container',
            toggleActions: 'play reset reverse none',
            start: 'top top',
            end: 'bottom top',
            scrub: true,
            pin: '.innovation-animation',
        });
    }, []);

    return (
        <div className="innovation-container">
            <div className="innovation-animation">
                <div className="innovation-elements">
                    <div className="innovation-title">
                        Elevate your <br /> leadership with <br />
                        <span className="innovation-span" ref={spanRef}>
                            ETH’s AI innovation
                        </span>
                    </div>
                    <div className="innovation-bird">
                        <InnovationBird />
                    </div>
                    <div className="innovation-desc" ref={desRef}>
                        The Leadership Companion is more than just a tool—it embodies ETH’s commitment to empowering leaders
                        with advanced, personalized support. At its core are custom fine-tuned models, developed at ETH using
                        cutting-edge AI technology, ensuring that every interaction seamlessly adapts to your needs.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Innovation;
