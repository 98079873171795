import React from "react";
import {
  ApproachImage,
  ApproachNoise,
  FirstIcon,
  SecondIcon,
  ThirdIcon,
} from "../../../../assets/img";
import useApproachAnimation from "../../../../hooks/useAppoach";

import "./Approach.scss";

const cardData = [
  {
    icon: <FirstIcon />,
    title: "The leadership challenge",
    text: "Leaders today face fast changes, remote teams, and high-stakes decisions. Traditional methods can’t keep up.",
  },
  {
    icon: <SecondIcon />,
    title: "The problem with traditional solutions",
    text: "Most programs offer generic advice and outdated strategies. They don’t adapt in real time when leaders need support the most.",
  },
  {
    icon: <ThirdIcon />,
    title: "How AI can help",
    text: "ETH’s Leadership Companion provides real-time, personalized insights, offering actionable guidance tailored to your leadership challenges.",
  },
];

const Approach = () => {
  const { approachLeftRef, approachRightRef } = useApproachAnimation();

  return (
    <div className="approach-container">
      <div className="approach-left-container" ref={approachLeftRef}>
        <div className="approach-text-box">
          <h2 className="approach-leadership">
            Why leadership needs <br />
            <div className="approach-highlight">a new approach</div>
          </h2>

          {cardData.map((card, index) => (
            <div className="approach-card" key={index}>
              <div>{card.icon}</div>
              <div className="approach-bold-text">{card.title}</div>
              <div className="approach-sub-text">{card.text}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="approach-image-container" ref={approachRightRef}>
        <img src="./women-office.png" alt="women" className="approach-image"/>
      </div>
    </div>
  );
};

export default React.memo(Approach);
