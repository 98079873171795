export const textContent = [
    {
        title: "Instant pain relief",
        text: "A supportive environment where you can articulate your vision and receive continuous encouragement to pursue your goals.",
    },
    {
        title: "Completely risk-free",
        text: "A supportive environment where you can articulate your vision and receive continuous encouragement to pursue your goals.",
    },
    {
        title: "Grow your vision",
        text: "A supportive environment where you can articulate your vision and receive continuous encouragement to pursue your goals.",
    },
    {
        title: "Take control",
        text: "Transform uncertainty into assured decision-making and lead with ease. Embark on a personalized journey to uncover your leadership.",
    },
    {
        title: "Discover your authenticity",
        text: "Embark on a personalized journey to uncover your authentic leadership style.",
    },
];