import { ReactComponent as Add } from '../../assets/icons/add.svg';
import { ReactComponent as Search } from '../../assets/icons/search.svg';
import { ReactComponent as Origami } from '../../assets/icons/origami.svg';
import { ReactComponent as OrigamiSmall } from '../../assets/icons/origami-small.svg';
import { ReactComponent as Lock } from '../../assets/icons/lock.svg';
import { ReactComponent as Timer } from '../../assets/icons/timer.svg';
import { ReactComponent as Visibility } from '../../assets/icons/visibility.svg';
import { ReactComponent as Microphone } from '../../assets/icons/microphone.svg';
import { ReactComponent as Dashboard } from '../../assets/icons/dashboard.svg';
import { ReactComponent as ViewList } from '../../assets/icons/view-list.svg';
import { ReactComponent as DashboardActive } from '../../assets/icons/dashboard-active.svg';
import { ReactComponent as ViewListActive } from '../../assets/icons/view-list-active.svg';
import { ReactComponent as ReaderMode } from '../../assets/icons/reader-mode.svg';
import { ReactComponent as Trailing } from '../../assets/icons/trailing.svg';
import { ReactComponent as Twitter } from '../../assets/icons/twitter.svg';
import { ReactComponent as LinkedIn } from '../../assets/icons/linkedIn.svg';
import { ReactComponent as More } from '../../assets/icons/more.svg';
import { ReactComponent as Flag } from '../../assets/icons/flag.svg';
import { ReactComponent as Delete } from '../../assets/icons/delete.svg';
import { ReactComponent as SSO } from '../../assets/icons/sso.svg';
import { ReactComponent as Discovery } from '../../assets/icons/discovery.svg';
import { ReactComponent as StarBorder } from '../../assets/icons/star-border.svg';
import { ReactComponent as Problem } from '../../assets/icons/problem.svg';
import { ReactComponent as Solution } from '../../assets/icons/solution.svg';
import { ReactComponent as Commitments } from '../../assets/icons/commitments.svg';
import { ReactComponent as Cross } from '../../assets/icons/cross.svg';
import { ReactComponent as ArrowForward } from '../../assets/icons/arrow-forward.svg';
import { ReactComponent as Check } from '../../assets/icons/check.svg';

const ICONS = {
    'add': Add,
    'search': Search,
    'origami': Origami,
    'origami_small': OrigamiSmall,
    'lock': Lock,
    'timer': Timer,
    'visibility': Visibility,
    'microphone': Microphone,
    'dashboard': Dashboard,
    'view_list': ViewList,
    'dashboard_active': DashboardActive,
    'view_list_active': ViewListActive,
    'reader_mode': ReaderMode,
    'trailing': Trailing,
    'twitter': Twitter,
    'linkedIn': LinkedIn,
    'more': More,
    'flag': Flag,
    'delete': Delete,
    'sso': SSO,
    'discovery': Discovery,
    'star_border': StarBorder,
    'problem': Problem,
    'solution': Solution,
    'commitments': Commitments,
    'cross': Cross,
    'arrow_forward': ArrowForward,
    'check': Check
}

export default ICONS;