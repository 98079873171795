import React from 'react';
import './Footer.scss';
import Text from '../../components/Text';

const Footer = () => {
    return (
        <div className="footer-container">
            <Text
                label="eth.footer.text"
                message="Your data is kept confidential and secure. Review our privacy policy for more information."
                xs
                lightBlack80
            />
        </div>
    );
};

export default Footer;
