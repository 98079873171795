import React from 'react';
import './BaseLayout.scss';
import { Outlet, useLocation } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LeftMenu from '../LeftMenu';
import RightSidebar from '../RightSidebar';
import { useAppSelector } from "../../store";


const BaseLayout = () => {
    const session = useAppSelector(state => state.session);
    const isLeftMenuActive = useAppSelector(state => state.setting.isLeftMenuOpen);

    const location = useLocation();

    return (
        <div className={`eth-base-layout ${isLeftMenuActive ? "" : "collapsed"}`}>
            <LeftMenu currentPath={location.pathname} />
            <div className='eth-base-layout-content'>
                <Header/>
                <Outlet/>
                <Footer/>
            </div>
            <RightSidebar sources={session.sourcesData} />
        </div>
    );
};

export default BaseLayout;
