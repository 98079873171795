export interface CustomIconProps {
    d: string;
    fill?: string;
    opacity?: number;
    width?: number;
    height?: number;
    className?: string;
}

export const CUSTOM_ICONS: { [key: string]: CustomIconProps } = {
    dashboard: {
      d: "M3 3H11V13H3V3ZM21 3H13V9H21V3ZM9 11V5H5V11H9ZM19 7V5H15V7H19ZM19 13V19H15V13H19ZM9 19V17H5V19H9ZM21 11H13V21H21V11ZM3 15H11V21H3V15Z",
      width: 24,
      height: 24,
    },
    view_list: {
      d: "M3.5 5V19H20.5V5H3.5ZM7.5 7V9H5.5V7H7.5ZM5.5 11V13H7.5V11H5.5ZM5.5 15H7.5V17H5.5V15ZM9.5 17H18.5V15H9.5V17ZM18.5 13H9.5V11H18.5V13ZM9.5 9H18.5V7H9.5V9Z",
      width: 24,
      height: 24,
    },
}
