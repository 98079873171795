import React from 'react';
import './Icon.scss';
import ICONS from './icons'


type Props = {
    type?: string,
    className?: string,
    children?: React.ReactNode,
    onClick?: (e: any) => void,
    style?: 'primary' | 'secondary'
};

const Icon = ({type, className, children, onClick, style}: Props) => {

    let IconSvg;

    if (type) {
        IconSvg = ICONS[type]
    } else {
        IconSvg = 'span';
    }

    if (children) {
        return (
            <div className="d-inline-block position-relative">
                <IconSvg onClick={(onClick ? onClick : () => {})} className={'icon ' + (className ? className : '')}/>
                {children}
            </div>
        );
    }

    return <IconSvg onClick={(onClick ? onClick : () => {})}
                    className={`icon ${className ? className : ''} ${style ? `icon-${style}` : ''}`}
    />;
};

export default Icon;