import React from 'react';
import './HeaderIllustration.scss';
import { getDaycycle } from '../../common';

type Props = {
    small?: boolean,
};


const HeaderIllustration = ({small}: Props) => {
    const dayCycle = getDaycycle();


    return (
        <>
            {(dayCycle === 'morning' || dayCycle === 'afternoon') &&
                <div className={`header-illustration-morning z-0 ${small ? 'small' : ''}`}>
                    <div className="header-illustration-1 position-absolute z-1"/>
                    <div className="header-illustration-2 position-absolute z-0"/>
                </div>
            }
            {(dayCycle === 'evening' || dayCycle === 'night') &&
                <div className={`header-illustration-evening z-0 ${small ? 'small' : ''}`}>
                    <div className="header-illustration-1 position-absolute z-0"/>
                    <div className="header-illustration-2 position-absolute z-1"/>
                    <div className="header-illustration-3 position-absolute z-1"/>
                    <div className="header-illustration-4 position-absolute z-0"/>
                    <div className="header-illustration-5 position-absolute top-0 z-0"/>
                    <div className="header-illustration-6 position-absolute top-0 z-0"/>
                </div>
            }
        </>
    );
};

export default HeaderIllustration;
