import { sessionConstants } from '../constants';
import { StoreDispatchType } from '../store';
import { Session } from '../types';


export const sessionActions = {
    sendMessage, loadSessions, setActiveSession, updatePrevActiveSession,
    addSession, setSources
};

function sendMessage(message: string) {
  return (dispatch: StoreDispatchType) => {
    dispatch((data => {
      return { type: sessionConstants.SET_MESSAGE, data }
    })({message}));
  };
}

function setActiveSession(session: Session) {
  return (dispatch: StoreDispatchType) => {
    dispatch((data => {
      return { type: sessionConstants.SET_ACTIVE_SESSION, data }
    })({session}));
  };
}

function loadSessions(sessions: Array<Session>) {
  return (dispatch: StoreDispatchType) => {
    dispatch((data => {
      return { type: sessionConstants.SET_SESSIONS, data }
    })({sessions}));
  };
}

function updatePrevActiveSession(session: Session) {
  return (dispatch: StoreDispatchType) => {
    dispatch((data => {
      return { type: sessionConstants.UPDATE_PREV_ACTIVE_SESSION, data }
    })({session}));
  };
}

function addSession(session: Session) {
  return (dispatch: StoreDispatchType) => {
    dispatch((data => {
      return { type: sessionConstants.ADD_SESSION, data }
    })({session}));
  };
}

function setSources(sources: Array<string>) {
  return (dispatch: StoreDispatchType) => {
    dispatch((data => {
      return { type: sessionConstants.SET_SOURCES, data }
    })({sources}));
  };
}
