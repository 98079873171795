import React, { useRef } from "react";
import "./Leadership.scss";
import useLeadershipAnimation from "../../../../hooks/useLeadership";

const LeaderShipSection: React.FC = () => {
  const firstSubtitleRef = useRef<HTMLDivElement>(null);
  const secondSubtitleRef = useRef<HTMLDivElement>(null);
  const spanRef = useRef<HTMLSpanElement>(null);

  useLeadershipAnimation(firstSubtitleRef, secondSubtitleRef, spanRef);

  return (
    <div className="leadership-container">
      <h2 className="features-title">
        <div className="feature-subtitle-first" ref={firstSubtitleRef}>
          Don't let{" "}
          <span className="features-span" ref={spanRef}>
            leadership challenges
          </span>
        </div>
        <br className="leadership-br" />
        <div className="feature-subtitle-second" ref={secondSubtitleRef}>
          hold back your innovation
        </div>
      </h2>
    </div>
  );
};

export default LeaderShipSection;
