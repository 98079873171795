import React from "react";
import { useDispatch } from "react-redux";
import { Session } from "../../types";
import { history, useAppSelector } from '../../store';
import { sessionActions } from "../../actions";
import { getSessions } from "../../utilities/apiService";

interface SessionContainerProps {
    sessions: Array<Session>;
    switchSession: (newChatId: string) => void;
}

const SessionContainer = ({ sessions, switchSession }: SessionContainerProps) => {
    const activeSession = useAppSelector(state => state.session.activeSession);
    const activeSessionIdLocal = localStorage.getItem("activeSessionId");
    const dispatch = useDispatch();

    function truncateString(str: string): string {
        return str && str.length > 20 ? `${str.slice(0, 20)}...` : str;
    }

    const handleSessionClick = async (session: Session) => {
        try {
            const sessionsToUpdate = await getSessions();
            sessionActions.loadSessions(sessionsToUpdate)(dispatch);
            if (sessionsToUpdate && sessionsToUpdate.length > 0) {
                const active = sessionsToUpdate.find(s => s.hash_id === session.hash_id);
                if (active) {
                    sessionActions.setActiveSession(active)(dispatch);
                    localStorage.setItem('activeSessionId', active.hash_id);
                    sessionActions.sendMessage(null)(dispatch);
                    switchSession(active.hash_id);
                }
            }
            localStorage.setItem('activeSessionId', session.hash_id );
            // history.push(`/session/${session.hash_id}`);
        } catch (error) {
            console.error('Error updating active session:', error);
        }
    };

    return (
        <div id="left-container">
            <h3>Sessions</h3>
            {sessions && sessions?.length ? sessions?.map((session, index) => (
                <p 
                    className={`session ${(session.hash_id === activeSession?.hash_id) || (session.hash_id === activeSessionIdLocal) ? "active-session" : ""}`}
                    onClick={async () => await handleSessionClick(session)}
                    key={session.hash_id}
                >
                    {truncateString(session.messages[0]?.content) || "New Session"}
                </p>
            )) : null}
        </div>
    );
}

export default SessionContainer;
